<template>
  <v-card fluid class="fill-height view library mb-12 pb-12" :class="{'px-4': !$vuetify.breakpoint.mobile}">
    <TopBar>
      <template v-slot:title>{{ genre }}</template>
    </TopBar>

    <v-card flat class="transparent-background">
      <v-card-text>
        <GenreList :genre="genre"/>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import GenreList from '@/components/GenreList'
import TopBar from '@/components/TopBar'

export default {
  metaInfo() {
    return {
      title: this.genre,
      meta: [{
        property: 'og:url',
        content: window.location.href
      }, {
        property: 'og:title',
        content: this.genre
      }, {
        property: 'og:description',
        content: this.$t('genre.default-meta-description', {genre: this.genre})
      }]
    }
  },

  props: {
    genre: {
      type: String,
      required: true
    }
  },

  data: () => ({}),

  components: {
    GenreList,
    TopBar
  },

  computed: {
  }
}
</script>

<style scoped>
</style>