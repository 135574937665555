var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LibraryList',{attrs:{"title":_vm.genre,"sortItems":[
    { text: _vm.$t('album.search.alphabetic'), value: 'album' },
    { text: _vm.$t('artist.label'), value: 'artist' },
    { text: _vm.$t('album.addedDate'), value: 'date' }
  ],"defaultSort":"date","storeConfig":{
    defaultCriteria: {genre: _vm.genre},
    getter: 'albums/genreAlbums',
    clearAction: 'albums/clearGenreAlbums',
    fetchAction: 'albums/getGenreAlbums'
  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var row = ref.row;
return [_c('AlbumItem',{attrs:{"item":row,"showArtist":""}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }