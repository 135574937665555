<template>
  <LibraryList
    :title="genre"
    :sortItems ="[
      { text: $t('album.search.alphabetic'), value: 'album' },
      { text: $t('artist.label'), value: 'artist' },
      { text: $t('album.addedDate'), value: 'date' }
    ]"
    defaultSort="date"
    :storeConfig="{
      defaultCriteria: {genre: genre},
      getter: 'albums/genreAlbums',
      clearAction: 'albums/clearGenreAlbums',
      fetchAction: 'albums/getGenreAlbums'
    }"
  >
    <template v-slot="{row}">
      <AlbumItem :item="row" showArtist></AlbumItem>
    </template>
  </LibraryList>
</template>

<script>
import AlbumItem from '@/components/AlbumItem'
import LibraryList from '@/components/LibraryList'

export default {
  props: {
    genre: {
      type: String,
      required: true
    }
  },

  data: () => ({
    row: {},
  }),

  components: {
    AlbumItem,
    LibraryList
  }
}
</script>

<style scoped>

</style>